import React, { Component } from 'react';
import './Home.css';
import 'semantic-ui-css/semantic.min.css';
import { Grid, Image, Button, Dropdown, Menu, Header, Icon, Modal } from 'semantic-ui-react'
import { NavLink, withRouter } from 'react-router-dom';
import navigation from '../../Images/selectsettings/menu.png';
import logo from '../../Images/logo-2.png'
import user from '../../Images/Group77.png';
import rightarrow from '../../Images/rightarrow.png';
import logout from '../../Images/logout.png';
import ConfirmationModal from '../Modal/ConfirmationModal'
import question from '../../Images/question.png';
import feedback from '../../Images/feedback.png';

// const dropdownhead = [
//   <div className="usrimgarea">
//       <img src={user} className="usericn" />
//       <img src={user} className="usericn" />

//   </div>
// ]

// const yesbtn="yes"
// const nobtn="No"

class MobileHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    }
  }
  openlogoutpage = () => {

    localStorage.clear();
    sessionStorage.clear();
    console.log("Logging ouT!!!!!!!!!!!!")
    this.props.history.push("/signin");
  }

  toggle = () => {

    this.setState(prevState => ({
      open: !prevState.open
    })
    )
  }

  render() {

    return (


      <div className="headerstart formobileheader"
      // style={{ top: '0', color: color, backgroundColor: backgroudcolor, boxShadow: boxshadow }}
      >

        <div className="headerlogo2 hidden-md">

          {/* <img src={navigation} className="hidden-md menuimg" /> */}
          <NavLink to="/Home/info" className="headerlogoimg"> <img src={logo} /></NavLink>
          <div className="usrimgarea">
            <NavLink to='/home/feedback'><img src={feedback} className="usericn feedbackimg" /></NavLink>
            <NavLink to='/home/profile'><img src={user} className="usericn" /></NavLink>
            {/* <NavLink to='/'><img src={logout} className="usericn logout" /></NavLink> */}
            <ConfirmationModal modalOpen={this.state.open} handleOpen={() => this.toggle()} handleClose={() => this.toggle()} ConfirmationModal="logoutmodal" modalcontnt="Do you really want to Sign Out from your account?"
              btntxt="Stay Connected" contentclass="logoutmodaltxt" modalheadr="Sign Out" modalbutton="modalbutnarea"
              btntxt1="Yes, Logout" functn={this.openlogoutpage} modalshowbtn={logout}
              modalcontntimg={question} modalhederclass="logoutmodalheader"
              modalbutnclass="cancelbutn" modalbutnclass1="yesbutn" modalshwbutncls=" logout" />
          </div>
          {/* <Dropdown text={dropdownhead}>
            <Dropdown.Menu>
              <NavLink to='/home/profile'>
                <Dropdown.Item text='Profile' />
              </NavLink>
              <NavLink to='/signin'>
                <Dropdown.Item text='Logout' />
              </NavLink>

            </Dropdown.Menu>
          </Dropdown> */}

        </div>
      </div>
    );
  }
}

export default withRouter(MobileHeader);
