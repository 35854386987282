

import moment from 'moment';

export const isDEV = () => {
    console.log(process.env.NODE_ENV);
    if (process.env.NODE_ENV == 'production')
        return false;
    else
        return true;

}

export const checkTableStatus = (id, extraminutes) => {
    return new Promise ((resolve, reject) => {
        console.log(id,extraminutes)
        if (id) {
            const reqValues = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            };
            fetch(process.env.REACT_APP_apiurl + "/table/find?id=" + id, reqValues)
                .then(result => result.json())
                .then(result => {
                    if (result.result) {
                        let table = result.result;
                        console.log(table)
                        if (table.status == 'Discharged' || table.status == 'Inactive' || !table.socketId) {
                            resolve(false);
                        }
                        else {
                            var currentUTChours = moment().utc().hours();
                            var currentUTCminutes = moment().utc().minutes();
                            let extraHours = 0;
                            if(extraminutes > 59 ||  currentUTCminutes ) 
                            {
                            extraHours = Math.floor(extraminutes/60);
                            extraminutes = extraminutes%60
                            }
                            if(currentUTCminutes+extraminutes>59)
                            {
                                extraHours = Math.floor(extraHours+(currentUTCminutes+extraminutes)/60);
                                extraminutes = (currentUTCminutes+extraminutes)%60
                            }
                            
                            console.log(table.blackoutStart.hours+":"+table.blackoutStart.minutes,currentUTChours+":"+currentUTCminutes, (currentUTChours+extraHours)+":"+(currentUTCminutes+extraminutes))
                        
                             
                            if (( (table.blackoutStart.hours === currentUTChours || table.blackoutStart.hours === currentUTChours+extraHours) &&
                                (table.blackoutStart.minutes <= currentUTCminutes || table.blackoutStart.minutes <= currentUTCminutes + extraminutes)) ||
                                (table.blackoutEnd.hours === currentUTChours && table.blackoutEnd.minutes >= currentUTCminutes)
                                || (
                                    (table.blackoutStart.hours < currentUTChours || table.blackoutStart.hours < currentUTChours+extraHours) &&
                                    table.blackoutEnd.hours > currentUTChours
                                )) {
                                resolve(false)

                            }
                            else {
                                resolve(true);
                            }

                        }
                    }
                    else {
                        resolve(false);
                    }
                })
                .catch(err => {
                    resolve(false);
                });
        }

        else
            resolve(false)
    })
}



export const checkServiceTimeAgainstTable = (table,extraminutes)=>{
    var currentUTChours = moment().utc().hours();
    var currentUTCminutes = moment().utc().minutes();
    let extraHours = 0;
    if(extraminutes > 59 ||  currentUTCminutes ) 
    {
        extraHours = Math.floor(extraminutes/60);
        extraminutes = extraminutes%60
    }
    if(currentUTCminutes+extraminutes>59)
    {
        extraHours = Math.floor(extraHours+(currentUTCminutes+extraminutes)/60);
        extraminutes = (currentUTCminutes+extraminutes)%60
    }
    
    console.log(table.blackoutStart.hours+":"+table.blackoutStart.minutes,currentUTChours+":"+currentUTCminutes, (currentUTChours+extraHours)+":"+(currentUTCminutes+extraminutes))

     
    if (( (table.blackoutStart.hours === currentUTChours || table.blackoutStart.hours === currentUTChours+extraHours) &&
        (table.blackoutStart.minutes <= currentUTCminutes || table.blackoutStart.minutes <= currentUTCminutes + extraminutes)) ||
        (table.blackoutEnd.hours === currentUTChours && table.blackoutEnd.minutes >= currentUTCminutes)
        || (
            (table.blackoutStart.hours < currentUTChours || table.blackoutStart.hours < currentUTChours+extraHours) &&
            table.blackoutEnd.hours > currentUTChours
        )) {
        return false;

    }
    else {
        return true;
    }
}