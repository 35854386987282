import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Home from './Component/Home/Home';
import * as serviceWorker from './serviceWorker';
import asyncComponent from './Component/AsyncComponent';
import { BrowserRouter, Route, Switch, Redirect, HashRouter } from 'react-router-dom';
import SignUp from './Component/Form/SignUp';
import SignIn from './Component/Form/SignIn';
import ForgotPassword from './Component/Form/ForgotPassword';
import QRScanner from './Component/Main/QRScanner';
import Payment from './Component/Main/Payment';
import Adminmain from './Component/Admin/Adminmain';
import SelectServiceDrawer from './Component/Main/SelectServiceDrawer';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import rootReducer from "../src/redux/rootReducer";
import Summary from './Component/Main/Summary';
import Alert from './Component/Main/Alert';
import SelectServiceDrawerv2 from "./Component/Main/SelectServiceDrawerv2";

// Async component for code splitting
// const SignUp = asyncComponent(() => import("./Component/Form/SignUp"));
// const SignIn = asyncComponent(() => import("./Component/Form/SignIn"));
// const ForgotPassword = asyncComponent(() => import("./Component/Form/ForgotPassword"));
// const QRScanner = asyncComponent(() => import("./Component/Main/QRScanner"));
// const Payment = asyncComponent(() => import("./Component/Main/Payment"));
// const Adminmain = asyncComponent(() => import("./Component/Admin/Adminmain"));
// const SelectServiceDrawer = asyncComponent(() => import("./Component/Main/SelectServiceDrawer"));
// const Summary = asyncComponent(() => import("./Component/Main/Summary"));
// const Alert = asyncComponent(() => import("./Component/Main/Alert"));
// const Home = asyncComponent(() => import("./Component//Home/Home"));





// let store = createStore(rootReducer, applyMiddleware(thunk))

let store = createStore(rootReducer, applyMiddleware(thunk))

ReactDOM.render(
    <Provider store={store}>
    <BrowserRouter>
        <Switch>
            <Route path='/home' component={Home} />
            <Route path='/signin' component={SignIn} />
            <Route path='/signup' component={SignUp} />
            <Route path='/forgotpassword' component={ForgotPassword} />
            <Route path='/qrscanner' component={QRScanner} />
            <Route path='/adminmain' component={Adminmain} />
            <Route path='/payment' component={Payment} />
            <Route path='/selectservicedrawer' component={SelectServiceDrawer}/>
            <Route path='/selectservicedrawerv2' component={SelectServiceDrawerv2}/>
            <Route path='/summary' component={ Summary} />
            <Route path='/alert' component={  Alert} />
           
            {/* <Route path='/selectsettings' component={SelectSettings} /> */}
            
            {/* <Route path='/map' component={mapPointer} /> */}

            {/* <Route path='/payment' component={Payment} /> */}

            <Route path='/' render={() =><Home/>
            
        
        
        } />

            
            {/* (<Redirect to="/signin" />) */}
           

            {/* EmployeeInfo */}

        </Switch>
    </BrowserRouter>
    </Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
