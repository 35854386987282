import React, { Component } from "react";
import "./Main.css";
import "semantic-ui-css/semantic.min.css";
import {
  Grid,
  Button,
  Form,
  Modal,
  GridColumn,
  Segment,
  Portal,
  Card,
  Icon
} from "semantic-ui-react";
import Header from "../Home/Header";
import "react-datepicker/dist/react-datepicker.css";
import Alert from "./Alert";
import rightarrow from "../../Images/rightarrow.png";
import MobileHeader from "../Home/MobileHeader";
import paypal from "../../Images/paypallogo.png";
import voucher from "../../Images/Voucher_.png";
import DropIn from "braintree-web-drop-in-react";
import * as actioncreators from "../../redux/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, NavLink } from "react-router-dom";
import Vouchericon from "../../Images/Vouchericon.png";
import check from "../../Images/check.png";
import checked from "../../Images/checked-symbol.png";
import Notification from "./Notification";
import feedback from '../../Images/feedback-desktop.png';
import { checkTableStatus } from "../../services/sharedService";

class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      voucherdivshow: false,
      showModal: true,
      currentCredit: 0,
      voucherAmount: 0,
      payment: false,
      open: false,
      modalOpen: false,
      vouchrmodalopen: false
    };
  }
  show = size => () => this.setState({ size, vouchrmodalopen: true });
  close = () => this.setState({ vouchrmodalopen: false, payment: false });
  paypalShow = size => () => this.setState({ size, payment: true })

  handleClose = () => this.setState({ open: false });
  handleOpen = () => this.setState({ open: true });

  openModal = () => {
    this.setState({ modalOpen: true });
    this.handleClose();
  };
  closeModal = () => this.setState({ modalOpen: false });

  async componentDidMount() {
    console.log(this.props.currentOrder);
    console.log(this.props.userDetail);
    const reqValues = {
      method: "GET",
      headers: {
        "authorization": localStorage.getItem("token")
      }
    };
    const response = await fetch(process.env.REACT_APP_apiurl +
      "/payment/gettoken", reqValues);
    console.log(response);
    const clientToken = await response.json(); // If returned as JSON string
    console.log(clientToken)

    this.setState({
      clientToken: clientToken.token
    });
    if (
      Object.keys(this.props.currentOrder).length === 0 &&
      this.props.currentOrder.constructor === Object
    ) {
      this.props.history.push("/Home/info");
    }
    this.setState({ currentCredit: this.props.userDetail.credit ? this.props.userDetail.credit.toFixed(2) : 0 });
  }

  callService = () => {
    const reqValues = {
      method: "PUT",
      // body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token")
      }
    };
    fetch(
      process.env.REACT_APP_apiurl + "/voucher/consume?id=" + this.state.redeem,
      reqValues
    )
      .then(result => result.json())

      .then(result => {
        console.log(result);
        if (result && result.result) {
          // dispatch(currentOrder(result.result));
          this.setState(
            {
              updatedUser: result.result,
              voucherAmount: result.voucherValue.toFixed(2),
              currentCredit: result.result.credit.toFixed(2)
            },
            () => {
              console.log(this.state.updatedUser);
            }
          );

          // alert("Voucher value: " + result.voucherValue + " credited to your profile")
          this.setState({
            modalOpen: true,
            alertheading: "Success",
            alertcontext:
              "Voucher value: $" +
              result.voucherValue.toFixed(2) +
              " credited to your profile",
            alertbutton: "Close",
            imgtype: "success"
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  activateLights = () => {
    const reqValues = {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    };
    fetch(
      process.env.REACT_APP_apiurl +
      "/control?order=" +
      this.props.currentOrder._id,
      reqValues
    )
      .then(result => result.json())

      .then(result => {
        console.log(result);
        if (result && !result.error) {
          console.log("showmodal ??");
          // this.setState({
          //     showModal: true
          // }, () => { console.log(this.state) })
          // this.openModal()

          this.setState({
            modalOpen: true,
            alertheading: "Success",
            alertcontext: "Shadecharger services started successfully!",
            alertbutton: "Close and go to homepage",
            imgtype: "success"
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  openvoucherfield = () => {
    console.log("hello");
    // this.setState({ voucherdivshow:true });
    this.setState(prevState => ({ voucherdivshow: !prevState.voucherdivshow }));
  };

  redeemCode = e => {
    let x = { [e.target.name]: [e.target.value] };
    console.log(x, "L72>>>>>>>");
    this.setState({ [e.target.name]: e.target.value }, () => {
      console.log(this.state);
    });
  };

  callPaypalService = async () => {
    if (this.state.nonce) {
      this.props.actions.getTableDetails();
      let tableStatus = await checkTableStatus(sessionStorage.getItem('tableId'),Math.max.apply(Math,this.props.currentOrder.services.map(function(o) { return o.duration/60; })));
      if(!tableStatus)
      {
      this.setState({
          modalOpen: true,
          alertheading: "Error",
          alertcontext: `This table's services are inactive or will become inactive in your usage interval. We request you to not pay for the services now and come back later.`,
          alertbutton: "Close and go to homepage",
          imgtype: "error",
          selectedServices: []
      });
      return;
    }
      let object = {
        orderId: this.props.currentOrder._id,
        nonce: this.state.nonce,
        amount: parseFloat(this.props.currentOrder.totalCost.toFixed(2))
      }
      console.log(object)
      const reqValues = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "authorization": localStorage.getItem('token')

        },
        body: JSON.stringify(object)


      };
      fetch(process.env.REACT_APP_apiurl + "/payment/paybybraintree", reqValues)
        .then(result => result.json())
        .then(result => {

          console.log(result)
          if (result) {
            this.setState({ nonce: null, payment: false });
            if (!result.error) {
              this.activateLights();
              // this.setState({
              //   modalOpen: true,
              //   alertheading: "Payment Successful!",
              //   alertcontext:
              //     "Your payment was successful",
              //   alertbutton: "Close",
              //   imgtype: "success"
              // });
            }
            else {
              this.setState({
                modalOpen: true,
                alertheading: "Payment Failed!",
                alertcontext:
                  "Your request for payment was not fulfilled. Kindly try again",
                alertbutton: "Close",
                imgtype: "error"
              });
            }


          }

        })
        .catch(err => {
          console.log(err);
        });
    }
    else {
      const { nonce } = await this.instance.requestPaymentMethod();
      this.setState({ nonce: nonce })
    }

  }


  payViaCreditModal = () => {
    console.log("show payViaCreditModal");

    if (this.state.currentCredit > this.props.currentOrder.totalCost) {
      this.setState({
        modalOpen: true,
        alertheading: "Credit balance to be used for payment",
        alertcontext:
          "Your current credit balance is: $" +
          this.state.currentCredit +
          ", $" +
          (this.props.currentOrder.totalCost).toFixed(2) +
          " will be debited.",
        alertbutton: "Pay from credit",
        imgtype: "warning"
      });
    } else {
      // alert("Your current credit balance is: " + this.state.currentCredit + ", which is less than order cost "
      //     + this.props.currentOrder.totalCost)

      this.setState({
        modalOpen: true,
        alertheading: "Credit balance low!",
        alertcontext:
          "Your current credit balance is: $" +
          this.state.currentCredit +
          ", which is less than order cost $" +
          this.props.currentOrder.totalCost +
          ". Please use a voucher to increase credit balance.",
        alertbutton: "Cancel",
        imgtype: "error"
      });
    }
  };

  payViaCredit = async() => {
    console.log(this.props.currentOrder);

    this.props.actions.getTableDetails();
    let tableStatus = await checkTableStatus(sessionStorage.getItem('tableId'),Math.max.apply(Math,this.props.currentOrder.services.map(function(o) { return o.duration/60; })));
    if(!tableStatus)
    {
    this.setState({
        modalOpen: true,
        alertheading: "Error",
        alertcontext: `This table's services are inactive or will become inactive in your usage interval. We request you to not pay for the services now and come back later.`,
        alertbutton: "Close and go to homepage",
        imgtype: "error",
        selectedServices: []
    });
    return;
  }
    let data = {
      orderId: this.props.currentOrder._id,
      transactionId: "testw/outpay001",
      amount: parseFloat(this.props.currentOrder.totalCost.toFixed(2)),
      paymentMethod: "credit",
      reference: "TESTW/OPAY",
      status: "paid",
      clientMAC: localStorage.getItem("clientMAC")

    };

    console.log(data, "Payment,L205>>>>>>>>>>>>");

    const reqValues = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token")
      }
    };
    fetch(process.env.REACT_APP_apiurl + "/payment/createwithcredit", reqValues)
      .then(result => result.json())

      .then(result => {
        console.log(result);

        this.setState({ currentCredit: result.user.credit.toFixed(2) });

        this.props.actions.userDetailDispatch(result.user);
        this.activateLights();
      })
      .catch(err => {
        console.log(err);
      });
    // }
  };

  findbutnfunction = () => {
    console.log("Findbutton function!!");

    if (this.state.alertbutton === "Pay from credit") {
      this.payViaCredit();
      this.closeModal();
    } else if (this.state.alertbutton === "Cancel" || this.state.alertbutton === "Close") {
      this.closeModal();
    } else if (this.state.alertbutton === "Close and go to homepage") {
      this.closeModal();
      this.props.history.push("/Home/info");
    }
  };

  render() {
    const { modalOpen, vouchrmodalopen, size } = this.state;
    return (
      <div className="paymentarea">
        <div className="hidden-md">
          <MobileHeader />
        </div>
        <div className="hidden-xs">
          <Header />
        </div>
        <div className="subpages">
          <NavLink to="/selectservicedrawerv2">
            <img src={rightarrow} alt="back" className="hidden-md arrwicn" />
          </NavLink>
          {/* <Grid divided='vertically' className="payment-btn" columns={1}>
                        <GridColumn> */}
          <div className="paymentbox">
            <div className="btn-area payment-methodarea">
              <div className="payment-heading paymntmthod-hedng">
                <p className="payment-para">Select your payment method</p>
              </div>
              <Grid>
                <Grid.Column computer={16} mobile={16} className="pdngbtom">
                  <Card className="paymentcard viacreditcard">
                    <Grid>
                      <Grid.Column computer={11} mobile={11}>
                        <div className="paypalbtn">
                          <h3 className="creditpaymnt">ShadeCharger Credit</h3>
                          <p className="avlblc">
                            current balance :{" "}
                            <span className="blcamnt">
                              ${this.state.currentCredit}
                            </span>
                          </p>
                        </div>
                      </Grid.Column>
                      <Grid.Column computer={5} mobile={5}>
                        <div className="paypalbtn cardpaybutn">
                          <Button
                            className="creditpaybutn buybtnnow"
                            onClick={() => this.payViaCreditModal()}
                          >
                            Pay
                          </Button>
                          {/* onClick={this.openModal} */}
                        </div>
                      </Grid.Column>
                    </Grid>
                  </Card>
                </Grid.Column>
                <Grid.Column computer={8} mobile={8} className="pdngbtom">
                  <Card className="paymentcard paypalvouchr-card" onClick={this.paypalShow('tiny')}>
                    <div className="paypalbtn">
                      <img src={paypal} alt="paypal" className="paypal-icon" />
                    </div>
                  </Card>
                </Grid.Column>
                <Grid.Column computer={8} mobile={8} className="pdngbtom">
                  <Card
                    className="paymentcard paypalvouchr-card"
                    onClick={this.show("tiny")}
                  >
                    <div className="paypalbtn voucherbtn">
                      <img
                        src={voucher}
                        alt="voucher"
                        className="paypal-icon voucher-icon"
                      />
                    </div>
                  </Card>
                </Grid.Column>
                <Modal
                  className="vouchermodal"
                  size={size}
                  open={vouchrmodalopen}
                  onClose={this.close}
                >
                  <Modal.Content className="voucher-modalcontent">
                    <Icon
                      onClick={this.close}
                      name="close"
                      className="closeicon"
                    />
                    <div className="voucherarea">
                      <div className="voucherimgarea">
                        <img
                          src={Vouchericon}
                          alt="voucher"
                          className="vouchricn"
                        />
                      </div>

                      <Grid>
                        <Grid.Column
                          mobile={16}
                          tablet={16}
                          computer={16}
                          className="spaceremove"
                        >
                          <div>
                            <div className="voucher">
                              <h4 className="vouchrheding">Voucher Details</h4>
                              <div className="defaultcard-area addnewcard">
                                <Form>
                                  <div className="addnewcardbutn">
                                    <p>
                                      {" "}
                                      <Form.Group widths="equal">
                                        <Form.Input
                                          fluid
                                          placeholder="Enter Voucher Code"
                                          name={"redeem"}
                                          onChange={this.redeemCode}
                                        />
                                      </Form.Group>
                                      <Button
                                        className="redeemvouchr-butn"
                                        onClick={() => {
                                          this.callService();
                                        }}
                                      >
                                        Redeem voucher
                                      </Button>
                                      <div className="totalvuchramnt">
                                        <ul className="vouchramntarea">
                                          {this.state.voucherAmount > 0 && (
                                            <div>
                                              <li className="vouchramnt-firstlist">
                                                <p className="voucheramnt-txt">
                                                  {" "}
                                                  <img
                                                    src={check}
                                                    className="checkicn"
                                                  />{" "}
                                                  Voucher amount credited:
                                                  {this.state.voucherAmount}
                                                </p>
                                              </li>
                                              <li>
                                                <p className="vouchramntdigit">
                                                  {" "}
                                                  ${this.state.voucherAmount}
                                                </p>
                                              </li>
                                            </div>
                                          )}
                                        </ul>
                                      </div>
                                      {this.state.voucherAmount > 0 && (
                                        <div className="addnewcardbutn  vouchrbutnarea">
                                          <Button
                                            onClick={() => {
                                              this.payViaCredit();
                                            }}
                                          >
                                            Pay and activate service
                                          </Button>
                                          {/* {this.state.showModal &&
                                                                                    <Modal className="alertmodal" >
                                                                                        <Modal.Content>
                                                                                            <Alert />
                                                                                        </Modal.Content>
                                                                                    </Modal>
                                                                                } */}
                                        </div>
                                      )}
                                    </p>
                                  </div>
                                </Form>
                              </div>
                            </div>
                          </div>
                        </Grid.Column>
                        {/* </Grid.Row> */}
                      </Grid>
                    </div>
                  </Modal.Content>
                </Modal>
              </Grid>
            </div>

            {/* <Notification open={open} portaltext="Your current credit balance is: 25, which is less than order cost 95"
                            handleClose={this.handleClose} doneFunction={this.openModal} donebutn="Done" closebutn="Close" /> */}
            <Modal className="vouchermodal" size={size} open={this.state.payment} onClose={this.close}>
              <Modal.Content className="voucher-modalcontent" >
                <DropIn
                  options={{ authorization: this.state.clientToken }}
                  onInstance={instance => (this.instance = instance)}
                />

                <p>Demo Card: 4111 1111 1111 1111</p>
                <p>Expiry: 09/20</p>
                <Button className="redeemvouchr-butn" onClick={() => {
                  this.callPaypalService()
                }}>Pay</Button>
              </Modal.Content>
            </Modal>

            <Modal open={modalOpen} onClose={this.closeModal}>
              <Modal.Content className="paymentsms-content">
                <Modal.Content>
                  <div className="closecrossbtn">
                    <Icon onClick={this.closeModal} name="close" />
                  </div>
                  <Alert
                    alertheading={this.state.alertheading}
                    alertcontext={this.state.alertcontext}
                    alertbutton={this.state.alertbutton}
                    imgtype={this.state.imgtype}
                    butnfunction={() => this.findbutnfunction()}
                  />
                  {/*this.closeModal */}
                </Modal.Content>
              </Modal.Content>
            </Modal>

            {this.state.voucherdivshow === true ? (
              <div className="voucherarea">
                <div className="voucherimgarea">
                  <img src={Vouchericon} className="vouchricn" />
                </div>
                <Grid>
                  <Grid.Column
                    mobile={16}
                    tablet={16}
                    computer={16}
                    className="spaceremove"
                  >
                    <div>
                      <div className="voucher">
                        <h4 className="vouchrheding">Voucher Details</h4>
                        <div className="defaultcard-area addnewcard">
                          <Form>
                            <div className="addnewcardbutn">
                              <p>
                                {" "}
                                <Form.Group widths="equal">
                                  <Form.Input
                                    fluid
                                    placeholder="Enter Voucher Code"
                                    name={"redeem"}
                                    onChange={this.redeemCode}
                                  />
                                </Form.Group>
                                <Button
                                  onClick={() => {
                                    this.callService();
                                  }}
                                >
                                  Redeem voucher
                                </Button>
                                <div className="totalvuchramnt">
                                  <ul className="vouchramntarea">
                                    {this.state.voucherAmount > 0 && (
                                      <div>
                                        <li className="vouchramnt-firstlist">
                                          <p className="voucheramnt-txt">
                                            {" "}
                                            <img
                                              src={check}
                                              className="checkicn"
                                            />{" "}
                                            Voucher amount credited:
                                            {this.state.voucherAmount}
                                          </p>
                                        </li>
                                        <li>
                                          <p className="vouchramntdigit">
                                            {" "}
                                            ${this.state.voucherAmount}
                                          </p>
                                        </li>
                                      </div>
                                    )}
                                  </ul>
                                </div>
                                {this.state.voucherAmount > 0 && (
                                  <div className="addnewcardbutn  vouchrbutnarea">
                                    <Button
                                      onClick={() => {
                                        this.payViaCredit();
                                      }}
                                    >
                                      Pay and activate service
                                    </Button>
                                    {this.state.showModal && (
                                      <Modal className="alertmodal">
                                        <Modal.Content>
                                          <div className="closecrossbtn">
                                            <Icon onClick={this.closeModal} name="close" />
                                          </div>
                                          <Alert />
                                        </Modal.Content>
                                      </Modal>
                                    )}
                                  </div>
                                )}
                              </p>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </Grid.Column>
                </Grid>
              </div>
            ) : null}
          </div>
          {/* </GridColumn>
                    </Grid> */}
        </div>
        {!this.state.loading &&
          <NavLink to="/home/feedback">
            <div className="menufeedback hidden-xs">
              <img src={feedback} className="usericn feedbackimg" />
              <span className="homenav feedbackdespotop">Give Feedback</span>
            </div>
          </NavLink>
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

function mapDispatchToProps(dispatch, state) {
  return {
    actions: bindActionCreators(actioncreators, dispatch)
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Payment)
);
