// AddTableForm
import React, { Component } from "react";
import "./Admin.css";
import "semantic-ui-css/semantic.min.css";
import {
  Grid,
  Image,
  Button,
  Container,
  Checkbox,
  Form,
  Icon,
  Input,
  Card,
  Radio,
  GridColumn,
  TextArea,
  Select
} from "semantic-ui-react";
// import { NavLink } from "react-router-dom";
import {
  DateInput,
  TimeInput,
  DateTimeInput,
  DatesRangeInput
} from "semantic-ui-calendar-react";
import ImageUploader from "react-images-upload";
import edit from "../../Images/edit.png";
import camera from "../../Images/camera.png";
import placeholdericn from "../../Images/placeholdericn.png";
import { Dropdown } from 'semantic-ui-react'

import * as actioncreators from "../../redux/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, NavLink } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// var moment = require('moment');
import moment from 'moment';
import 'moment-timezone';

// const [startDate, setStartDate] = new Date();
const portOptions = [
  { key: 1, value: 1, text: 1 },
  { key: 2, value: 2, text: 2 },
  { key: 3, value: 3, text: 3 },
  { key: 4, value: 4, text: 4 },
  { key: 5, value: 5, text: 5 },
  { key: 6, value: 6, text: 6 },
  { key: 7, value: 7, text: 7 },
  { key: 8, value: 8, text: 8 },
];
const companyname = [
  { key: "a", value: "a", text: "abc" },
  { key: "b", value: "b", text: "abc" },
  { key: "b", value: "b", text: "abc" }
];

class AddTableListForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
      time: "",
      dateTime: "",
      datesRange: "",
      files: [],
      data: [],
      editVisible: false,
      pictures: [],
      numberofServices: [],
      services: [],
      details: {},
      imageId: null,
      formData: {},
      serviceOptions: [],
      companyListData: [{ key: "b", value: "b", text: "Loading.." }],
      startDate: new Date(),
      startDate1: new Date(),
      companyid: {},
      timezone: null,
      selectValue: '',
      onBtn: false,
    };
    this.onDrop = this.onDrop.bind(this);
    this.onChange = this.onChange.bind(this);
  }


  onDrop(picture) {
    this.setState({
      pictures: this.state.pictures.concat(picture)
    });
  }
  onImageChange = event => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = e => {
        this.setState({ image: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  // handleChange = (event, { name, value }) => {
  //   if (this.state.hasOwnProperty(name)) {
  //     this.setState({ [name]: value });
  //   }
  // };
  onImageChange = event => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = e => {
        this.setState({ image: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  // handleChange = (event, { name, value }) => {
  //   if (this.state.hasOwnProperty(name)) {
  //     this.setState({ [name]: value });
  //   }
  // };

  onChange(e) {
    e.preventDefault();
    console.log(e.target.files[0], "L101>>>")
    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    for (var pair of formData.entries()) {
      console.log(pair[0], pair[1]);
    }
    const config = {
      method: "POST",
      body: formData
    };

    fetch(process.env.REACT_APP_apiurl + "/uploads", config)
      .then(response => response.json())

      .then(response => {
        // console.log(result);
        console.log(response, `file upload successful.`);
        this.setState({
          imageId: response.id
        });

        //       }
      })
      .catch(err => {
        console.log(err);
      });
  }

  onChangeHandler = event => {
    console.log(event.target.files[0]);

    let arr =
      // this.state.data.push(event.target.files[0].name)

      [...this.state.data, event.target.files[0]];

    this.setState({ data: arr }, () => {
      console.log(this.state.data);
    });
    // this.setState({ date: datearr }, () => { console.log(this.state.date) })
  };
  editOption = () => {
    // debugger;
    console.log(this.state.services, this.state.numberofServices);
    let val = this.state.numberofServices.length + 1;
    console.log(this.state.services, this.state.numberofServices)
    let arr = [...this.state.numberofServices];
    console.log(this.state.services, this.state.numberofServices)
    arr.push(val);
    // debugger;
    console.log(arr, this.state.services, this.state.numberofServices)
    this.setState({ editVisible: true, numberofServices: arr }, () => {
      // debugger;
      console.log(this.state.services, this.state.numberofServices)
    });
  };
  closeeditOption = () => {
    this.setState({ editVisible: false });
  };

  enterServiceDetails = (x, e, name) => {
    console.log(x, name);
    console.log(e);
    console.log(this.state.services)
    var filteredArr = [];
    var obj = {};
    if (x.id !== undefined) {
      filteredArr = this.state.services.filter(z => {
        return z.id !== x.id;
      });

      obj = this.state.services.find(z => {
        return z.id === x.id;
      });

    }
    else {
      filteredArr = this.state.services.filter(z => {
        return z.id !== x;
      });

      obj = this.state.services.find(z => {
        return z.id === x;
      });
    }
    console.log(obj);
    if (obj !== undefined) {
      if (name) {
        //radio
        // console.log()
        if (name.checked === true) {
          obj["currentStatus"] = "good";
        } else {
          obj["currentStatus"] = "in use";
        }
      } else {
        obj[e.target.name] = e.target.value;
      }
      filteredArr.push(obj);
    } else {
      let newObj = {};

      newObj.id = x;
      if (name) {
        //radio
        // console.log()  "currentStatus": "good"
        if (name.checked === true) {
          newObj["currentStatus"] = "good";
        } else {
          newObj["currentStatus"] = "in use";
        }
      } else {
        console.log(e.target.name, e.target.value)
        newObj[e.target.name] = e.target.value;
      }
      filteredArr.push(newObj);
    }

    this.setState({ services: filteredArr }, () => {
      console.log(this.state.services);
    });
  };

  handleChangeAddress = e => {
    let obj = this.state.formData;

    // obj["location"]={};

    obj["location"]
      ? (obj["location"] = obj["location"])
      : (obj["location"] = {});

    obj["location"]["address"] = e.target.value;

    this.setState({ formData: obj });
  };

  handleChangeShortDescription = e => {
    let obj = this.state.formData;
    obj["shortDescription"] = e.target.value
    this.setState({ formData: obj });
  };



  handleChangeStartTime = date => {
    console.log(date, this.state.timezone, "l83>>>>", moment().utc(date), "UTC")
    var updatedDate
    if (this.state.timezone !== null) {
      updatedDate = moment(date).tz(this.state.timezone)
      // ("America/Los_Angeles")
    }
    else {
      updatedDate = moment(date)
    }



    console.log(moment(date).format("DD-MM-YYYY h:mm:ss A"), "Local")
    console.log(moment(updatedDate).format("DD-MM-YYYY h:mm:ss A"), "Los Angeles")
    console.log(moment().utc(updatedDate).toDate(), "UTC")
    console.log(moment.utc(updatedDate).hours())
    console.log(moment.utc(updatedDate).minutes())




    var momentLocalObj = moment(date)

    var momentUtcObj = moment().utc(date)

    console.log(momentLocalObj, momentUtcObj)

    console.log(momentLocalObj.toDate(), momentUtcObj.toDate())




    let startObj = {};

    startObj['fullSpecLocal'] = updatedDate.toDate();
    startObj['fullSpecUTC'] = moment().utc(updatedDate).toDate()
    startObj['hours'] = moment.utc(updatedDate).hours();
    startObj['minutes'] = moment.utc(updatedDate).minutes();

    let obj = { ...this.state.formData };
    // Handling w/ pure JS
    // let x= new Date(date)

    // .toUTCString();
    // console.log(x,"L90>>")
    // console.log(x.getTime())
    // let y= Math.floor(x.getTime()+x.getTimezoneOffset()*60*1000)
    // console.log(y,"l92>>>>")
    // Stopped handling with pure JS
    obj["blackoutStart"] = startObj
    this.setState({
      startDate: momentLocalObj.toDate(),
      formData: obj
    });
  };
  handleChangeEndTime = date => {
    let obj = { ...this.state.formData };

    // let x= new Date(date)
    // // .toUTCString();
    // console.log(x,"L90>>")
    // // let z=new Date()
    // console.log(x.getTime())
    // console.log(x.getTimezoneOffset()*60*1000)
    // let y= Math.floor(x.getTime()+x.getTimezoneOffset()*60*1000)
    // console.log(y,"l92>>>>")


    var momentLocalObj = moment(date)

    var momentUtcObj = moment().utc(date)

    var updatedDate
    if (this.state.timezone !== null) {
      updatedDate = moment(date).tz("America/Los_Angeles")
    }
    else {
      updatedDate = moment(date)
    }
    let endObj = {};

    endObj['fullSpecLocal'] = updatedDate.toDate();
    endObj['fullSpecUTC'] = moment().utc(updatedDate).toDate()
    endObj['hours'] = moment.utc(updatedDate).hours();
    endObj['minutes'] = moment.utc(updatedDate).minutes();
    obj["blackoutEnd"] = endObj;
    this.setState({
      startDate1: momentLocalObj.toDate(),
      formData: obj
    });
  };


  handleChangecoOrdinates = e => {
    let obj = this.state.formData;

    // obj["location"]={};
    // obj["location"]
    //   ? (obj["location"] = obj["location"])
    //   : (obj["location"] = {});
    if (!obj["location"]) {
      obj["location"] = {};
    }
    if (!obj["location"]["coOrdinates"]) {
      obj["location"]["coOrdinates"] = {};
    }
    //   ? (obj["location"]["coOrdinates"] = obj["location"]["coOrdinates"])
    //   : (obj["location"]["coOrdinates"] = {});

    obj["location"]["coOrdinates"][e.target.name] = e.target.value;

    this.setState({ formData: obj });
  };

  changeNetwork = e => {
    let obj = this.state.formData;

    obj["network"] ? (obj["network"] = obj["network"]) : (obj["network"] = {});
    obj["network"][e.target.name] = e.target.value;

    this.setState({ formData: obj });
  };

  addTable = () => {
    console.log(this.state.numberofServices);
    console.log(this.state.services);
    console.log(this.state.imageId);
    console.log(this.state.formData);
    console.log(this.props.storeselectedcompany);

    let saveServices = this.state.services.map(x => {
      delete x.id;
      x.cost = Number(x.cost);
      x.currentStatus = x.currentStatus === "good" ? "good" : "unavailable"
      return x;
    });

    console.log(saveServices);

    // if(this.props.editData.services)
    // {
    //   saveServices=[...saveServices,this.props.editData.services]
    // }
    var tableData = this.state.formData;
    tableData.companyId = this.state.formData.companyId || this.state.storeselectedcompany.value;

    // let formData={};
    // formData.companyId = this.state.companyid;
    //     console.log(formData)
    // tableData["status"] = "good";
    if (tableData["location"])
      tableData["location"]["type"] = "new";
    tableData["tableNumber"] = this.props.editData.tableNumber;
    tableData["services"] = saveServices;
    // tableData["companyId"]=this.props.storeselectedcompany.value
    console.log(this.state.imageId);
    if (this.state.imageId !== null) {
      tableData["imageId"] = this.state.imageId;
    }

    console.log(tableData);
    if (Object.keys(this.props.editData).length === 0 && this.props.editData.constructor === Object) {  //edit table 
      const reqValues = {
        method: "POST",
        body: JSON.stringify(tableData),
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token")
        }
      };

      fetch(process.env.REACT_APP_apiurl + "/table/create", reqValues)
        .then(result => result.json())
        .then(result => {
          console.log(result);

          if (!result.error) {
            this.props.actions.createTable(result);
            this.props.onClose();
          }
        })
        .catch(err => {
          console.log(err);
        });
    }

    else {
      //save table
      console.log(this.props.editData)
      console.log(this.state.formData)
      if (this.props.editData.companyId) {
        this.setState({ formData: this.props.editData })
        // if (this.state.formData.companyId) {
        tableData.companyId = this.state.formData.companyId
        const reqValues = {
          method: "PUT",
          body: JSON.stringify(tableData),
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token")
          }
        };

        fetch(process.env.REACT_APP_apiurl + "/table/update?id=" + this.props.editData._id, reqValues)
          .then(result => result.json())
          .then(result => {
            console.log(result);

            if (!result.error) {
              this.props.actions.editTable(result.result);
              this.props.onClose();
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
      else {
        // if(this.props.storeselectedcompany ===0){
        alert("Please select a company for this table!")
        // }
      }
    }
  };

  componentDidMount() {
    // if(this.props.editData){
    //   var companyList=this.props.allCompany
    //   console.log(companyList);
    //   var index= companyList.findIndex(x=>x.value===this.props.editData.companyId);
    //   console.log(index);
    //   this.setState({selectValue:companyList[index].value},()=>console.log(this.state.selectValue))
    // };

    console.log(this.props.storeselectedcompany, 'L489>>')
    if (this.props.editData.timezone) {
      this.setState({ timezone: this.props.editData.timezone })
    }
    if (this.props.editData.lightStatus) {
      this.setState({ onBtn: this.props.editData.lightStatus })
    }
    console.log(this.props.editData.lightStatus);
    // this.setState({ formData: this.props.editData.companyId })

    console.log(this.state.formData)
    var serviceListArrayDropdown = this.props.servicesList.map(x => {

      let obj = {}

      obj.key = x.name;
      obj.value = x.name;
      obj.text = x.name;

      return obj;
    })

    var companyListData = this.props.companyList.map(x => {


      var obj = {};
      obj.key = x._id;
      obj.value = x._id;
      obj.text = x.companyName;
      return obj;
    })

    console.log(companyListData)

    console.log(serviceListArrayDropdown)
    this.setState({
      serviceOptions: serviceListArrayDropdown,
      companyListData: companyListData
    }, () => {
      console.log(this.state.companyListData)
    })
    if (
      this.props.editData &&
      this.props.editData.services &&
      this.props.editData.services.length > 0
    ) {

      // var serviceArray = this.props.editData.services.map(x=>{

      //   delete x._id;
      //   return


      // })

      var serviceArray = this.props.editData.services
      for (let i = 1; i <= serviceArray.length; i++) {
        delete serviceArray[i - 1]._id
        serviceArray[i - 1].id = i

      }
      console.log(serviceArray)

      this.setState(
        {
          editVisible: true,
          numberofServices: serviceArray,
          services: serviceArray
        },
        () => {
          console.log(this.state.numberofServices, this.state.services);
        }
      );
    }
    if (this.props.editData.imageId) {
      this.setState({ imageId: this.props.editData.imageId });
    }

    let obj = {};
    if (this.props.editData.network) {
      obj["network"] = this.props.editData.network;
    }
    if (this.props.editData.imageId) {
      obj["imageId"] = this.props.editData.imageId;
    }
    if (this.props.editData.location) {
      console.log(this.props.editData.location)
      obj["location"] = this.props.editData.location;
    }
    this.setState({ formData: obj }, () => {
      console.log(this.state.formData)

      var companyobj = { ...this.state.formData }

      if (this.props.editData.companyId) {
        console.log(this.props.editData.companyId)

        companyobj.companyId = this.props.editData.companyId;
        console.log(companyobj, "L30")
        this.setState({ formData: companyobj }, () => { console.log(this.state.formData) })
      }
      else if (this.props.userDetail.role === "companyAdmin") {
        const reqValues = {
          method: "GET",
          // body: JSON.stringify(tableData),
          headers: {
            "Content-Type": "application/json",
            // authorization: localStorage.getItem("token")
          }
        };

        fetch(process.env.REACT_APP_apiurl + "/company/getCompanybyUserId?id=" + this.props.userDetail._id, reqValues)
          .then(result => result.json())
          .then(result => {
            console.log(result);

            if (!result.error) {
              // this.setState({companyid: result.result._id})
              companyobj.companyId = result.result._id

              console.log(companyobj, "L30")
              this.setState({ formData: companyobj }, () => { console.log(this.state.formData) })
            }
            console.log(this.state.companyid)
          })
          .catch(err => {
            console.log(err);
          });
      }
    });

    if (this.props.editData.blackoutStart && this.props.editData.blackoutStart.fullSpecLocal) {


      // let x= new Date()
      // // .toUTCString();
      // console.log(x,"L90>>")
      // // let z=new Date()
      // let y=x.getTimezoneOffset()*60*1000

      // console.log(y,"l92>>>>")

      // if(y<0)
      // {

      //   let impTimeStamp= this.props.editData.blackoutStart-y

      //   let date= new Date(impTimeStamp)

      //   this.setState({startDate:date})

      // }
      // else{

      //   let impTimeStamp= this.props.editData.blackoutStart+y

      //   let date= new Date(impTimeStamp)

      //   this.setState({startDate:date})
      // }

      let date = new Date(this.props.editData.blackoutStart.fullSpecLocal)

      this.setState({ startDate: date })




    }

    if (this.props.editData.blackoutEnd && this.props.editData.blackoutEnd.fullSpecLocal) {

      // let x= new Date()
      // // .toUTCString();
      // console.log(x,"L90>>")
      // // let z=new Date()
      // let y=x.getTimezoneOffset()*60*1000

      // console.log(y,"l92>>>>")

      // if(y<0)
      // {

      //   let impTimeStamp= this.props.editData.blackoutEnd-y;

      //   let date= new Date(impTimeStamp)

      //   this.setState({startDate1:date})
      // }
      // else{

      //   let impTimeStamp= this.props.editData.blackoutEnd+y;

      //   let date= new Date(impTimeStamp)

      //   this.setState({startDate1:date})

      // }

      // let impTimeStamp= this.props.editData.blackoutEnd-y;

      let date = new Date(this.props.editData.blackoutEnd.fullSpecLocal)

      this.setState({ startDate1: date })

    }




  }

  componentWillReceiveProps(newProps) {
    if (!Object.is(newProps.storeselectedcompany, this.props.storeselectedcompany)) {
      console.log(this.newProps.storeselectedcompany.value)
    }

    if (newProps.editData.lightStatus) {
      this.setState({ imageId: this.props.editData.imageId });
    }
  }

  deleteService = (x) => {
    console.log(x)
    console.log(this.state.services, this.state.numberofServices)

    if (x.id) {
      let newServices = this.state.numberofServices.filter(z => {
        return z.id !== x.id
      })
      console.log(x.id, "L 674", newServices)
      let saveNewServices = this.state.services.filter(z => {
        return z.id !== x.id
      })
      console.log("L 679", saveNewServices)
      this.setState({
        numberofServices: newServices,
        services: saveNewServices
      }, () => {
        // debugger;
        console.log(this.state.services, this.state.numberofServices)
      })
    }
    else {
      console.log("in deleteService else")
      let newServices = this.state.numberofServices.filter(z => {
        return z !== x
      })
      let saveNewServices = this.state.services.filter(z => {
        return z.id !== x
      })
      this.setState({
        numberofServices: newServices,
        services: saveNewServices
      }, () => {
        // debugger;
        console.log(this.state.services, this.state.numberofServices)
      })

    }
  }
  onClicked = () => {

    console.log(this.state.onBtn, this.props.editData.lightStatus);
    const reqValues = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      }
    };

    fetch(process.env.REACT_APP_apiurl + "/table/testrelays?tableNumber=" + this.props.editData.tableNumber + '&status=' + (!this.state.onBtn ? 1 : 0), reqValues)
      .then(result => result.json())
      .then(result => {
        console.log(result);

        if (!result.error) {

          this.setState({ onBtn: !this.state.onBtn }, () => { console.log(this.state.onBtn) })
        }
      })
      .catch(err => {
        console.log(err);
      });

  }
  render() {
    // const [startDate, setStartDate] = useState(new Date());
    return (
      <div className="tableliststart adminvoucherform">
        <div style={{ maxWidth: "100%" }} className="tablediv voucherform">
          <div className="formfieldarea">
            <Form className="formfield">
              <Form.Field className="uploadfield">
                <div className="uploaderarea">
                  <div className="uploadimgarea">
                    {/* <img id="target" src={this.state.image ? this.state.image : placeholdericn} className="uploadimg" /> */}
                    {this.state.imageId && (
                      <img
                        className="tblelistimg"
                        src={
                          process.env.REACT_APP_apiurl +
                          "/getimages/" +
                          this.state.imageId
                        }
                        alt="table"
                      />
                    )}
                  </div>
                  <p className="uploadertext">
                    Max file size:5mb accepted jpg, png
                  </p>
                  <div className="imgaeuploader">
                    <input type="file" name="file" onChange={this.onChange} />
                    <Button className="chooseimgbutn">Choose Images</Button>
                  </div>
                  {/* <img id="target" src={this.state.image !==null?this.state.image :placeholdericn} className="profileimgcls" /> */}
                </div>
              </Form.Field>
              {/* <div className="networkarea">
                <h3 className="tableservc-heding networkhedng">Network</h3>
                <Grid>
                  <Grid.Column computer={8} className="border-remove">
                    <Form.Field>
                      <label className="labelnme">IP</label>
                      <Input
                        // placeholder={
                        //   this.props.editData.network
                        //     ? this.props.editData.network.ip
                        //     : ""
                        // }
                        defaultValue={ this.props.editData.network
                          ? this.props.editData.network.ip
                          : ""}
                        iconPosition="left"
                        type="text"
                        name="ip"
                        onChange={e => this.changeNetwork(e)}
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column computer={8} className="border-remove">
                    <Form.Field>
                      <label className="labelnme">Host Name</label>
                      <Input
                        defaultValue={
                          this.props.editData.network
                            ? this.props.editData.network.hostName
                            : ""
                        }
                        iconPosition="left"
                        type="text"
                        name="hostName"
                        onChange={e => this.changeNetwork(e)}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid>
              </div> */}
              <Grid>
                <Grid.Column computer={8}>
                  {this.props.userDetail.role === "admin" &&
                    <Form.Group className="companyselect tlcompnyslct" inline>
                      <label className="labelcompany">
                        Select Company :
                      </label>
                      {/* <Select placeholder='Select Comapny' options={companyname} /> */}
                      <Select className="companyinputselect selectcompany" placeholder="Select Company"
                        defaultValue={this.props.storeselectedcompany.value ? this.props.storeselectedcompany.value : this.props.editData.companyId}
                        options={this.state.companyListData}
                        onChange={(e, data) => {
                          console.log(e, "L840")

                          // let obj = { ...this.state.formData }
                          let obj = this.props.editData.companyId ? this.props.editData : this.state.formData
                          obj.companyId = data.value;


                          this.setState({ formData: obj }, () => {
                            console.log(this.state.formData, 'L836>>')
                          })


                        }}
                      // })}
                      />
                    </Form.Group>
                  }
                </Grid.Column>
                {this.props.editData && this.props.editData._id && <Grid.Column computer={8}>
                  <Form.Group className="companyselect tlcompnyslct" inline style={{ justifyContent: 'flex-end' }}>
                    <label className="labelcompany">
                      Test Relay
                      </label>
                    {/* <Select placeholder='Select Comapny' options={companyname} /> */}
                    {/* <Button
                    onClick={this.onClicked}
                    className={(this.state.onBtn === false ? 'activebtncss' : ' ') + ' ' + "onoffbuttoncss"}>
                    {this.state.onBtn === false ? 'ON' : 'OFF'}
                  </Button> */}
                    <Radio
                      checked={this.state.onBtn}
                      toggle
                      onChange={this.onClicked}
                      name="enabled"
                      className="testrelaytoggle"
                    />
                  </Form.Group>

                </Grid.Column>}
              </Grid>

              <Form.Field>
                <label className="labelnme">Short Description</label>
                <TextArea
                  defaultValue={
                    this.props.editData.shortDescription
                  }
                  iconPosition="left"
                  type="text"
                  name="address"
                  onChange={e => this.handleChangeShortDescription(e)}
                />
              </Form.Field>

              {/* <Form.Field>
                      <label className="labelnme">Address</label>
                      <TextArea
                        defaultValue={
                          this.props.editData.location
                            ? this.props.editData.location.address
                            : ""
                        }
                        iconPosition="left"
                        type="text"
                        name="address"
                        onChange={e => this.handleChangeAddress(e)}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid>
              </div> */}

              <div className="tableservicesec">
                <Grid>
                  <Grid.Column computer={8}>
                    <h3 className="tableservc-heding">Service</h3>
                  </Grid.Column>
                  <Grid.Column computer={8}>
                    <div className="addtableservice">
                      <Button className="addsrvcbutn" onClick={this.editOption}>
                        <Icon name="plus" />
                      </Button>
                    </div>
                  </Grid.Column>
                  <Grid.Column computer={16}>
                    {this.state.editVisible === true ? (
                      // {
                      //   this.statenumberofServices
                      // }
                      <div className="addsection">
                        {this.state.numberofServices.map(x => {
                          console.log(x, "L829")
                          return (
                            <div key={x.id} className="addtblelistmain">
                              <Form>
                                <Grid>
                                  <Grid.Column
                                    computer={8} mobile={16}
                                    className="border-remove"
                                  >
                                    <Form.Field>
                                      <label className="labelnme">
                                        Service Name
                                      </label>
                                      {/* <Select placeholder="Service" options={countryOptions} /> */}
                                      {/* <Input
                                        defaultValue={x.name ? x.name : ""}
                                        iconPosition="left"
                                        type="text"
                                        name="name"
                                        onChange={  e =>
                                          this.enterServiceDetails(x, e)
                                        }
                                      /> */}
                                      <Dropdown className="" placeholder='Service name' defaultValue={x.name ? x.name : 'Service name'} search
                                        selection options={this.state.serviceOptions} onChange={(e, data) => {
                                          console.log(data)
                                          let obj = { target: {} };


                                          obj.target.name = "name"
                                          obj.target.value = data.value



                                          this.enterServiceDetails(x, obj)
                                        }
                                        } />
                                    </Form.Field>
                                  </Grid.Column>
                                  <Grid.Column
                                    computer={8} mobile={16}
                                    className="border-remove"
                                  >
                                    <Form.Field>
                                      <label className="labelnme">
                                        Cost (per 15 minutes)
                                      </label>
                                      <Input
                                        defaultValue={x.cost ? x.cost : ""}
                                        iconPosition="left"
                                        type="number"
                                        name="cost"
                                        onChange={e =>
                                          this.enterServiceDetails(x, e)
                                        }
                                      />
                                    </Form.Field>
                                  </Grid.Column>
                                  <Grid.Column
                                    computer={8} mobile={16}
                                    className="border-remove"
                                  >
                                    <Form.Field>
                                      <label className="labelnme">
                                        Port Number
                                      </label>

                                      <Dropdown placeholder='Port Number'
                                        fluid
                                        multiple
                                        selection
                                        defaultValue={x.ports ? x.ports : 'Select ports'}
                                        options={portOptions} onChange={(e, data) => {
                                          console.log(data)
                                          let obj = { target: {} };


                                          obj.target.name = "ports"
                                          obj.target.value = data.value



                                          this.enterServiceDetails(x, obj)
                                        }
                                        } />
                                    </Form.Field>
                                  </Grid.Column>
                                  <Grid.Column
                                    computer={8} mobile={5}
                                    className="border-remove deleicon"
                                  >
                                    <Form.Field>
                                      <label className="labelnme">Enabled</label>
                                      <Radio
                                        defaultChecked={
                                          x.currentStatus === "good"
                                            ? true
                                            : false
                                        }
                                        toggle
                                        name="enabled"
                                        onChange={(e, name) =>
                                          // let e={}
                                          this.enterServiceDetails(x, e, name)
                                        }
                                      />
                                    </Form.Field>
                                    {/* <div className="deleteservc">
                                      <Icon
                                        name="trash alternate outline"
                                        className="trashicon"
                                        onClick={() => { this.deleteService(x) }}
                                      />
                                    </div> */}
                                  </Grid.Column>
                                </Grid>
                              </Form>
                              <div className="deleteservc">
                                {/* <Icon
                                        name="trash alternate outline"
                                        className="trashicon"
                                        onClick={() => { this.deleteService(x) }}
                                      /> */}
                                <Button className="tbledeletebtn" onClick={() => { this.deleteService(x) }}>
                                  Delete
                                </Button>

                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                  </Grid.Column>
                </Grid>
              </div>
              <div className="location-area blackoutpmain">
                <Grid>
                  <Grid.Column computer={16} className="border-remove">
                    <h3 className="tableservc-heding">Blackout Period</h3>
                  </Grid.Column>

                  <Grid.Column computer={8} mobile={16} className="border-remove">
                    <Form className="blackoutdiv">
                      <label className="labelnme">Start Time</label>
                      <DatePicker
                        selected={this.state.startDate}
                        onChange={this.handleChangeStartTime}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={5}
                        timeCaption="Time"
                        dateFormat="hh:mm aa"
                      />
                    </Form>
                  </Grid.Column>
                  <Grid.Column computer={8} mobile={16} className="border-remove">
                    <Form className="blackoutdiv">
                      <label className="labelnme">End Time</label>
                      <DatePicker
                        selected={this.state.startDate1}
                        onChange={this.handleChangeEndTime}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={5}
                        timeCaption="Time"
                        dateFormat="hh:mm aa"
                      />
                    </Form>
                  </Grid.Column>
                </Grid>
              </div>

              <div className="location-area">
                <Grid>
                  <h3 className="tableservc-heding">Location</h3>
                  <Grid.Column computer={16} className="border-remove">
                    <Form.Field>
                      <label className="labelnme">Address</label>
                      <TextArea
                        defaultValue={
                          this.props.editData.location
                            ? this.props.editData.location.address
                            : ""
                        }
                        iconPosition="left"
                        type="text"
                        name="address"
                        onChange={e => this.handleChangeAddress(e)}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid>
              </div>
              {
                this.props.editData && this.props.editData.timezone &&
                <div>
                  <p className="coordintetext">Timezone: {this.props.editData.timezone}</p>
                  {/* <p></p> */}
                </div>
              }

              <div className="coordinates-area">
                <p className="coordintetext">Co-ordinates:</p>
                <Grid>
                  <Grid.Column computer={8} className="border-remove">
                    <Form.Field>
                      <label className="labelnme">Latittude</label>
                      <Input
                        defaultValue={
                          this.props.editData.location &&
                            this.props.editData.location.coOrdinates
                            ? this.props.editData.location.coOrdinates.latitude
                            : ""
                        }
                        iconPosition="left"
                        type="text"
                        name="latitude"
                        onChange={e => this.handleChangecoOrdinates(e)}
                      />
                      {/* coOrdinates */}
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column computer={8} className="border-remove">
                    <Form.Field>
                      <label className="labelnme">Longitude</label>
                      <Input
                        defaultValue={
                          this.props.editData.location &&
                            this.props.editData.location.coOrdinates
                            ? this.props.editData.location.coOrdinates.longitude
                            : ""
                        }
                        iconPosition="left"
                        type="text"
                        name="longitude"
                        onChange={e => this.handleChangecoOrdinates(e)}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid>
              </div>
              {/* <div className="location-area blackoutpmain lightsettingsarea">
                <Grid>
                  <Grid.Column computer={16} className="border-remove">
                    <h3 className="tableservc-heding">Light Settings</h3>
                  </Grid.Column>
                </Grid>
                <div className="lightsettingbuttongroup">
                  {this.state.btngroup.map((item) => {
                    //  let idx= item.index;
                    return (
                      <Button onClick={() => this.onClicked(item)}
                        className={(this.state.activebtn === item.index ? 'activebtncss' : '') + ' ' + "onoffbuttoncss"}>
                        {item.name}
                      </Button>
                    )
                  })}
                </div>
              </div> */}

              <div className="formbutn addeditmodal-formbutn">
                <Button type="submit" onClick={this.addTable}>
                  Submit{" "}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

// export default AddTableListForm;

const mapStateToProps = state => {
  return state;
};

function mapDispatchToProps(dispatch, state) {
  return {
    actions: bindActionCreators(actioncreators, dispatch)
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AddTableListForm)
);
