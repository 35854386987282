
import React, { Component } from 'react';
import './Main.css';
import { Grid, Image, Button, Container, Modal } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import { Tab } from 'semantic-ui-react'
import phonepe from '../../Images/paypal.png';
import wifi from '../../Images/wifistatus.png';
import plug from '../../Images/plugcolor.png';
import * as actioncreators from "../../redux/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Alert from './Alert';
import DropIn from "braintree-web-drop-in-react";
import { async } from 'q';

var moment = require('moment');


class Voucher extends Component {


    constructor(props) {
        super(props);
        this.state = {
            vouchers: [],
            voucherBuyValues: [5, 10, 15, 20, 30],
            modalOpen: false,
            openmodel: false,
            Message: null,
            showModel:false
        };
    }

    //////////////////////////////create order///////////////////////////////////////////////////////////////////
    createorder = (x) => {
        console.log(this.state.voucherBuyValues);
        let data = {
            type: "voucher",
            tableId: this.props.selectedTable._id,
            orderedBy: this.props.userDetail._id,
            services: [
                {
                    cost: x
                },
            ]

        };


        const reqValues = {
            method: "POST",
            body: JSON.stringify(data),

            headers: {
                "Content-Type": "application/json"
            }
        };
        fetch(
            process.env.REACT_APP_apiurl +
            "/order/create",
            reqValues
        )
            .then(
                result =>

                    result.json()

            )

            .then(result => {
                console.log(result);


                if (!result.error) {
                    this.setState({ id: result.result._id })
                    this.setState({ cost: result.result.totalCost })
                    // this.setState({
                    //     // vouchers: filteredVoucherArray,
                    //     alertheading: "order created successfully!!",
                    //     alertcontext: "You order worth " ,
                    //     alertbutton: "Done",
                    //     imgtype: "success"
                    // })
                    this.openModal()

                }

                //     else {
                //         // alert(result.message)
                //         this.setState({
                //             alertheading: "order created unsuccessfull!!",
                //             alertcontext: " order creattion failed",
                //             alertbutton: "Done",
                //             imgtype: "error"
                //         })
                //         this.openModal()
                // }
            })



            .catch(err => {
                console.log(err);
            });


    };


    ///////////////////////////////////////////////////////////////////////////////////////////////////

    async componentDidMount() {
        this.setState({ Message: null })
        console.log(this.props.voucherList)
        //   this.props.actions.getVoucherList()  <<DO NOT UNCOMMENT THIS!!
        // get voucher list>>
        console.log(this.props.userDetail)
        this.getVouchers()

        console.log(this.props.currentOrder);
        console.log(this.props.userDetail);
        const reqValues = {
            method: "GET",
            headers: {
                "authorization": localStorage.getItem("token")
            }
        };
        const response = await fetch(process.env.REACT_APP_apiurl +
            "/payment/gettoken", reqValues);
        console.log(response);
        const clientToken = await response.json(); // If returned as JSON string
        console.log(clientToken)

        this.setState({
            clientToken: clientToken.token
        });


    }

    paymentfunc = async () => {
        if (this.state.nonce) {

            let object = {
                orderId: this.state.id,
                nonce: this.state.nonce,
                amount: parseFloat(this.state.cost)
            }
            console.log(object)
            const reqValues = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "authorization": localStorage.getItem('token')

                },
                body: JSON.stringify(object)


            };
            fetch(process.env.REACT_APP_apiurl + "/payment/paybybraintree", reqValues)
                .then(response => response.json())
                .then(response => {

                    console.log(response)
                    if (!response.error) {
                        this.setState({ nonce: null, payment: false });
                        // if (response) {
                            this.buyVoucher();

                            // this.openModal();

                        // }
                        // else {
                        //     this.setState({
                        //         modalOpen: true,
                        //         alertheading: "Payment Failed!",
                        //         alertcontext:
                        //             "Your request for payment was not fulfilled. Kindly try again",
                        //         alertbutton: "Close",
                        //         imgtype: "error"
                        //     });
                        // }
                        // this.openModal();

                    }

                })
                .catch(err => {
                    console.log(err);
                });
        }
        else {
            const { nonce } = await this.instance.requestPaymentMethod();
            this.setState({ nonce: nonce })
        }

    }




    getVouchers = () => {
        // const reqValues = {
        //     method: "GET",
        //     headers: {
        //         "Content-Type": "application/json",
        //         "authorization": localStorage.getItem("token")
        //     }
        // };
        // fetch(process.env.REACT_APP_apiurl + "/voucher/findByUser", reqValues)
        //     .then(result => result.json())
        //     .then(result => {
        //         console.log(result)
        const reqValues = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              authorization: localStorage.getItem("token")
            }
          };
          fetch(process.env.REACT_APP_apiurl + "/voucher/getAllActiveVoucher", reqValues)
            .then(result => result.json())
            .then(result => {
              console.log(result);
                this.setState({ vouchers: result.result })


            })
            .catch(err => {
                console.log(err);

            });
    }
    


    consumeVoucher = (x) => {
        const reqValues = {
            method: "PUT",
            // body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                authorization: localStorage.getItem("token")
            }
        };
        fetch(
            process.env.REACT_APP_apiurl + "/voucher/consume?id=" + x.redeemId,
            reqValues
        )
            .then(result => result.json())

            .then(result => {
                console.log(result);
                if (result && result.result) {
                    // dispatch(currentOrder(result.result));
                    this.setState(
                        {
                            updatedUser: result.result,
                            voucherAmount: result.voucherValue.toFixed(2),
                            currentCredit: result.result.credit.toFixed(2)
                        },
                        () => {
                            console.log(this.state.updatedUser);
                        }
                    );

                    // alert("Voucher value: " + result.voucherValue + " credited to your profile")
                    this.setState({
                        showModel: true,
                        alertheading: "Success",
                        alertcontext:
                            "Voucher value: $" +
                            result.voucherValue.toFixed(2) +
                            " credited to your profile",
                        alertbutton: "Close",
                        imgtype: "success"
                    });
                }
            })
            .catch(err => {
                console.log(err);
            });
    };




    buyVoucher = () => {
        // console.log(x, "voucher value")

        let data = {
            value: this.state.cost,
            identifierId: "individual" + this.props.userDetail._id + this.state.vouchers.length
        }
        const reqValues = {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "authorization": localStorage.getItem("token")
            }
        };
        fetch(process.env.REACT_APP_apiurl + "/voucher/create", reqValues)
            .then(result => result.json())
            .then(result => {

                console.log(result)

                if (!result.error) {
                    // this.setState({ value: result.result.value })
                    let voucherArr = [result.result, ...this.state.vouchers]

                    this.setState({ Message: result.message })
                    this.setState({
                        vouchers: voucherArr,
                        alertheading: "Voucher Created ",
                        alertcontext: "Your Voucher Created successfull!! ",
                        alertbutton: "Done",
                        imgtype: "success"
                    })

                    this.voucherModel()

                }
                else {

                    this.setState({
                        alertheading: "Voucher Created ",
                        alertcontext: "Your Voucher Created successfull!! ",
                        alertbutton: "Done",
                        imgtype: "error"
                    })

                    this.voucherModel()


                }
            })
            .catch(err => {
                this.setState({
                    alertheading: "Voucher Created ",
                    alertcontext: "Your Voucher Created successfull!! ",
                    alertbutton: "Done",
                    imgtype: "error"
                })

                this.voucherModel()
                console.log(err);

            });

    }
    





    voucherModel = () => {
        this.setState({ openmodel: true });

    }

    openModal = () => {
        this.setState({ modalOpen: true });

    }
    closeModal = () => {
        this.setState({ modalOpen: false })
        this.setState({ openmodel: false })
        this.setState({ showModel: false })
    }

showmodel = () => {
    this.setState({ showModel: true });

}

    render() {
        const { showModel } = this.state
        const { modalOpen } = this.state
        const { openmodel } = this.state
        return (
            <div className="historydiv subpages voucherpage">
                <div className="servcetabarea">
                    <Tab menu={{ secondary: true, pointing: true }}
                        panes={[
                            {
                                menuItem: 'Add Credit', render: () => <Tab.Pane attached={false}>
                                    {this.state.vouchers.length > 0 ? this.state.vouchers.map(x => {
                                        return (

                                            <div id={x._id}>
                                                <div className="buyvoucher">
                                                    <div className="historypayment mb0 bbottom1">
                                                        <div className="historyinner">
                                                            <div className="hpaymenttext paytext">
                                                                <p className="historyinnertext">
                                                                    Voucher Details
                                                    <p className="benefits">
                                                                        <b>Redeem Id :</b> {x.redeemId}
                                                                    </p>
                                                                    <p className="benefits">
                                                                        <b> Expires on :</b> {moment(x.expiry).format("DD/MM/YYYY")}
                                                                    </p>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="historypayment mb0">
                                                        <div className="historyinner">
                                                            <div className="hpaymenttext paytext">
                                                                <div className="historyamount">
                                                                    <p className="paymentamount voucheramount"> ${x.value} </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="historyamount">
                                                            <Button className="buybtnnow addcreditbtn" onClick={() => this.consumeVoucher(x)}> Add to Credit </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }) : <div>
                                            <p className="novouchersfound">No active vouchers found! Please buy one!!</p>
                                        </div>}
                                </Tab.Pane>
                            },

                            {
                                menuItem: 'Buy Now', render: () => <Tab.Pane attached={false}>
                                    {this.state.voucherBuyValues.map(x => {
                                        return (


                                            <div className="buyvoucher">
                                                <div className="historypayment mb0 bbottom1">
                                                    <div className="historyinner">
                                                        <div className="hpaymenttext paytext">
                                                            <p className="historyinnertext">
                                                                Voucher Details
                                                    <p className="benefits">
                                                                    <b>Benefits :</b> ${x}
                                                                </p>
                                                                <p className="benefits">
                                                                    <b>Expires in :</b> 1 year
                                                    </p>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="historypayment mb0">
                                                    <div className="historyinner">
                                                        <div className="hpaymenttext paytext">
                                                            <div className="historyamount">
                                                                <p className="paymentamount voucheramount"> ${x} </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="historyamount">
                                                        <Button className="buybtnnow" onClick={() => this.createorder(x)}> BUY NOW </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </Tab.Pane>
                            },

                        ]} />

                    <Modal open={showModel} onClose={this.closeModal}>
                        <Modal.Content>
                            <Alert alertheading={this.state.alertheading}
                                alertcontext={this.state.alertcontext}
                                alertbutton={this.state.alertbutton} imgtype={this.state.imgtype} butnfunction={this.closeModal} />
                        </Modal.Content>
                    </Modal>

                   

                    <Modal className="vouchermodal" open={modalOpen} onClose={this.closeModal}>
                        <Modal.Content className="voucher-modalcontent" >
                            <DropIn
                                options={{ authorization: this.state.clientToken }}
                                onInstance={instance => (this.instance = instance)}
                            />

                            <p>Demo Card: 4111 1111 1111 1111</p>
                            <p>Expiry: 09/20</p>
                            <Button className="redeemvouchr-butn" onClick={() => {
                                this.paymentfunc()
                                //   this.findbutnfunction()
                            }}>Pay</Button>

                        </Modal.Content>
                    </Modal>
                    {this.state.Message && this.state.Message === "Voucher created Successfully" &&
                        <Modal open={openmodel} onClose={this.closeModal}>
                            <Modal.Content>
                                <Alert alertheading={this.state.alertheading}
                                    alertcontext={this.state.alertcontext}
                                    alertbutton={this.state.alertbutton} imgtype={this.state.imgtype}
                                    // butnfunction={this.closeModal}
                                    butnfunction={() => this.closeModal()}
                                />
                            </Modal.Content>
                        </Modal>
                    }
                </div>
            </div>
        );
    }
}

// export default Voucher;


const mapStateToProps = state => {
    return state;
};

function mapDispatchToProps(dispatch, state) {
    return {
        actions: bindActionCreators(actioncreators, dispatch)
    };
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Voucher)
);

